<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Edit Pengeluaran'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'expenses/' + form.id"
              type="edit"
            />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from '@/component/expenses/Form.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: '',
        name: '',
        amount: '',
        expense_category_id: '',
        notes: '',
        flow_type: '1',
        photo: [],
        photo_name: ''
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get('expenses/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/expenses/list')
      } else {
        this.form['_method'] = "put"
        this.form.photo_name = this.form.photo
        this.form.photo = []
        this.form.flow_type = '1'
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan" },
      { title: "Pengeluaran", route: '/expense/list' },
      { title: 'Edit Pengeluaran' }
    ])
    // Get Data
    this.get()
  },

}
</script>

<style>
</style>